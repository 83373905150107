<template>
  <button
    :class="[prefix ? `btn btn_${prefix}` : 'btn']"
    type="button"
    @click="btnEvent"
  >
    <div class="btn__icon" v-if="hasIcon">
      <svg
        width="135"
        height="154"
        viewBox="0 0 135 154"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M122.48 108.764L67.1031 140.832L11.7262 108.764V44.5817L67.1031 12.514L122.48 44.5817V108.764ZM134.205 111.684V41.6622C134.205 39.3078 132.934 37.0946 130.909 35.9173L70.4465 0.882924C68.3746 -0.294308 65.8788 -0.294308 63.8069 0.882924L3.29722 35.9173C1.27239 37.0946 0.000976562 39.3078 0.000976562 41.6622V111.731C0.000976562 114.085 1.27239 116.299 3.29722 117.476L63.8069 152.51C64.8429 153.122 65.973 153.405 67.1031 153.405C68.2333 153.405 69.4105 153.122 70.4465 152.51L130.909 117.429C132.981 116.252 134.205 114.038 134.205 111.684Z"
          fill="#0F4DF2"
        />
        <path
          d="M27.6601 72.6611C25.2637 75.0574 25.2637 78.9426 27.6601 81.3389L66.7106 120.389C69.1069 122.786 72.9922 122.786 75.3885 120.389C77.7848 117.993 77.7848 114.108 75.3885 111.712L40.6769 77L75.3885 42.2884C77.7848 39.8921 77.7848 36.0068 75.3885 33.6105C72.9922 31.2142 69.1069 31.2142 66.7106 33.6105L27.6601 72.6611ZM134.275 70.8638L31.999 70.8638V83.1362L134.275 83.1362V70.8638Z"
          fill="#0F4DF2"
        />
      </svg>
    </div>
    <p v-if="label">{{ label }}</p>
  </button>
</template>

<script>
export default {
  name: "GameBtn",
  props: {
    label: {
      type: String,
      default: "",
    },
    prefix: {
      type: String,
      default: "",
    },
    hasIcon: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    btnEvent() {
      this.$emit("btnEvent");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/base/global.scss";
.btn {
  background: none;
  border: none;

  &_fill {
    background: $main-color;
    border-radius: 10px;
    padding: 8px 12px;
    border: 1px solid #fff;
    align-self: flex-end;
    cursor: pointer;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  }

  p {
    margin: 0;
    color: #fff;
    font-family: "PolymerDisplay";
    font-size: 20px;
    @include tablet {
      font-size: 18px;
    }
    @include mobile {
      font-size: 14px;
    }
  }

  &__icon {
    height: 50px;
    cursor: pointer;
    svg {
      height: 100%;
      width: 100%;
    }
  }

  &_white {
    svg {
      path {
        fill: white;
      }
    }
  }
}
</style>
