<template>
  <div class="home">
    <games-list></games-list>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "HomeView",
};
</script>
