const puzzleSizes = {
  vertical: {
    rowCount: 3,
    columnCount: 4,
    mobileMini: {
      width: 320,
      height: 400,
      pieceSize: 65,
    },
    mobile: {
      width: 460,
      height: 580,
      pieceSize: 100,
    },
    tablet: {
      width: 750,
      height: 890,
      pieceSize: 170,
    },
    desktop: {
      width: 750,
      height: 930,
      pieceSize: 170,
    },
  },
  square: {
    rowCount: 4,
    columnCount: 4,
    mobileMini: {
      width: 320,
      height: 320,
      pieceSize: 46,
    },
    mobile: {
      width: 460,
      height: 460,
      pieceSize: 75,
    },
    tablet: {
      width: 680,
      height: 680,
      pieceSize: 120,
    },
    desktop: {
      width: 710,
      height: 710,
      pieceSize: 125,
    },
  },
};

const breakpoints = {
  mobileMini: 460,
  mobile: 768,
  tablet: 1024,
  desktopSmall: 1366,
};

export { puzzleSizes, breakpoints };
